var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-full flex justify-end"},[_c('a-input-search',{staticClass:"md:w-1/4 w-full mb-4",model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"bg-white"},[_c('a-table',{key:("table-template-" + _vm.pagination),attrs:{"data-source":_vm.searchResult,"pagination":{
          defaultPageSize: _vm.pagination,
          size: _vm.isMobile() ? 'small' : 'large',
          showSizeChanger: true,
         },"rowKey":function (record) { return record.user_id; },"scroll":{ x: true },"bordered":"","expandRowByClick":""},scopedSlots:_vm._u([{key:"expandedRowRender",fn:function(record){return _c('div',{},[(record.referrals > 0)?_c('full-referrals-nested-ts',{attrs:{"referrals":record.nestedReferrals,"user":record,"pagination":10}}):_c('empty-data')],1)}}])},[_c('a-table-column',{key:"index",attrs:{"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var indexPosition = ref.indexPosition;
return _c('span',{},[_vm._v(" "+_vm._s(indexPosition)+" ")])}}])},[_c('a-icon',{attrs:{"slot":"title","type":"number"},slot:"title"})],1),_c('a-table-column',{key:"name",scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var user_name = ref.user_name;
         var user_login = ref.user_login;
         var avatar = ref.avatar;
return _c('user-main-info',{attrs:{"avatar":avatar,"login":user_login,"name":user_name}})}}])},[_c('a-icon',{attrs:{"slot":"title","type":"user"},slot:"title"})],1),_c('a-table-column',{key:"referrals",attrs:{"align":"center","sorter":function (a, b) { return a.referrals - b.referrals; }},scopedSlots:_vm._u([{key:"default",fn:function(record){return _c('span',{},[_vm._v(" "+_vm._s(record.referrals)+" ")])}}])},[_c('a-icon',{attrs:{"slot":"title","type":"team"},slot:"title"})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }